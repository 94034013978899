<template>
  <Card title="签署方">
    <template slot="title">
      <span class="card-handle">
        <van-icon name="question-o" color="#cccccc" @click="help" />
        <span class="txt">
          {{ templateData.signOrderFlag ? '顺序签' : '无序签' }}
        </span>
      </span>
    </template>

    <template v-for="item in partnerInfo">
      <!-- 内部参与方 -->
      <Inside
        v-if="item.partnerRole === 0"
        :partener="item"
        :key="item.guid"
        @change="(data) => processChange(data, item.guid)"
      />

      <!-- 外部个人 -->
      <Individual
        v-else-if="item.partnerRole === 1"
        :partener="item"
        :key="item.guid"
        @change="(data) => processChange(data, item.guid)"
      />

      <!-- 外部企业 -->
      <Company
        v-else-if="item.partnerRole === 2"
        :partener="item"
        :key="item.guid"
        @change="(data) => processChange(data, item.guid)"
      />
    </template>
  </Card>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import Card from './Card.vue'
import Inside from './Inside.vue'
import Individual from './Individual.vue'
import Company from './Company.vue'

export default {
  components: { Card, Inside, Individual, Company },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      /** 是否为企业用户 */
      isComUser: (state) => state.user.isComUser,
      /** 用户信息 */
      userInfo: (state) => state.user.userInfo,
      templateData: (state) => state.publishModel.templateData,
    }),
    partnerInfo: {
      get() {
        return this.$store.state.publishModel.partnerInfo
      },
      set(val) {
        val.forEach((item, index) => {
          item.defaultName = `参与方${index + 1}`
        })
        this.updatePartnerInfo([...val])
      },
    },
  },
  mounted() {},
  methods: {
    ...mapMutations('publishModel', ['setPartnerInfo', 'updatePartnerInfo']),

    processChange(data, key) {
      const find = this.partnerInfo.find((item) => item.guid === key)
      Object.assign(find, data)
    },

    selectSignOrder(val) {
      this.templateData.signOrderFlag = val.value
    },

    help() {
      this.$dialog
        .alert({
          title: '说明',
          message:
            '【无序签】对签署顺序没有要求，多方可以同时签署 \n 【顺序签】希望指定签署前后顺序，A->B->C',
        })
        .then(() => {})
    },
  },
}
</script>

<style lang="less" scoped>
.card-handle {
  font-size: 13px;

  .txt {
    padding-left: 4px;
    position: relative;
    font-weight: 400;
  }
}
.process-card {
  border: 1px solid #eeeeee;
  border-radius: 4px;
  box-shadow: 0px 2px 6px 0px #f6f8fb;
  margin-top: 14px;

  .add-participants {
    padding: 16px 12px;
    .name {
      font-size: 15px;
      color: #111a34;
    }
    .group-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;
    }
    .van-button__text,
    .van-button__content {
      color: #0075ff;
      font-weight: bold;
    }
    .van-button__content {
      padding: 0 8px;
    }
  }
}
</style>
