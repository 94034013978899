<template>
  <Card title="抄送方">
    <template slot="title">
      <span class="card-handle" @click="toggle">
        <span class="txt">抄送</span>
        <van-icon :name="`arrow-${toggleType}`" />
      </span>
    </template>

    <transition name="van-fade">
      <div v-show="toggleType === 'up'">
        <div class="make-copy" v-for="item in sendUserList" :key="item.guid">
          <img
            class="delete-icon"
            src="@/assets/imgs/编组 17@2x.png"
            @click.stop="delCopy(item.guid)"
          />

          <template v-if="item.type === 0">
            <div class="rule-item">
              <div class="rule-name">姓名</div>
              <div class="rule-value">
                <input
                  class="title-input"
                  placeholder="请输入真实姓名"
                  type="text"
                  v-model="item.userName"
                />
                <van-icon
                  class="addressBook"
                  name="manager"
                  @click="selectProcess(item.guid)"
                />
              </div>
            </div>
          </template>

          <template v-else>
            <div class="rule-item">
              <div class="rule-name">企业名称</div>
              <div class="rule-value">
                <input
                  class="title-input"
                  placeholder="请输入企业名称"
                  type="text"
                  v-model="item.comName"
                />
                <van-icon
                  class="addressBook"
                  name="manager"
                  @click="selectProcess(item.guid)"
                />
              </div>
            </div>
            <div class="rule-item">
              <div class="rule-name">经办人姓名</div>
              <div class="rule-value">
                <input
                  class="title-input"
                  placeholder="请输入真实姓名"
                  type="text"
                  v-model="item.userName"
                />
              </div>
            </div>
          </template>

          <div class="rule-item">
            <div class="rule-name">手机/邮箱</div>
            <div class="rule-value">
              <input
                class="title-input"
                placeholder="请输入手机/邮箱"
                type="text"
                v-model="item.telephone"
              />
            </div>
          </div>
        </div>
        <div class="add-handle">
          <div class="button-make" @click="addCopy(0)">
            <van-icon name="plus" />
            抄送个人
          </div>
          <div class="split">|</div>
          <div class="button-make" @click="addCopy(1)">
            <van-icon name="plus" />
            抄送企业
          </div>
        </div>
      </div>
    </transition>

    <PersonalFriend
      :show="addressBookShow"
      :type="1"
      append-to-body
      @onClose="addressBookShow = false"
      @roleCallback="personConfirm"
    />
  </Card>
</template>
<script>
  import { mapState } from 'vuex'
  import PersonalFriend from '@/components/selectPerson/personalFriend.vue'
  import { getRandom } from '@/utils/index'
  import Card from './Card.vue'

  export default {
    components: { Card, PersonalFriend },
    data() {
      return {
        toggleType: 'down',
        addressBookShow: false,
        guid: '',
      }
    },
    computed: {
      ...mapState('publishModel', {
        templateData: state => state.templateData,
        sendUserList: state => state.templateData.sendUserList,
      }),
    },
    mounted() {},
    methods: {
      toggle() {
        this.toggleType = this.toggleType === 'up' ? 'down' : 'up'
      },

      /**
       * 添加抄送人
       * @param {string} type - 抄送人类型: 0:个人 1:企业
       * @returns {void}
       */
      addCopy(type) {
        if (this.sendUserList.length >= 10) {
          this.$toast('最多添加10个抄送人')
          return
        }
        this.sendUserList.push({
          comId: '',
          comName: '',
          uid: '',
          userName: '',
          telephone: '',
          type,
          guid: getRandom(10),
        })
        if (this.sendUserList.length) {
          this.templateData.assignSender = 1
        }
      },

      /**
       * 从抄送用户列表中删除指定的用户
       * @param {string} guid - 要删除的用户的guid
       */
      delCopy(guid) {
        const index = this.sendUserList.findIndex(item => item.guid === guid)
        this.sendUserList.splice(index, 1)
      },

      selectProcess(guid) {
        this.guid = guid
        this.addressBookShow = true
      },

      personConfirm(val) {
        const find = this.sendUserList.find(item => item.guid === this.guid)
        find.comId = val.comId
        find.comName = val.comName
        find.uid = val.uid
        find.userName = val.name
        find.telephone = val.telephone
        this.addressBookShow = false
        this.guid = ''
      },
    },
  }
</script>

<style lang="less" scoped>
  .card-handle {
    font-size: 14px;

    .txt {
      margin-right: 4px;
    }
  }

  .make-copy {
    border: 1px solid #eeeeee;
    border-radius: 4px;
    box-shadow: 0px 2px 6px 0px #f6f8fb;
    margin-top: 14px;
    padding: 12px;
    padding-top: 16px;
    position: relative;

    .delete-icon {
      position: absolute;
      right: -1px;
      top: 0;
      width: 16px;
      height: 14px;
    }
  }
  .addressBook {
    font-size: 16px;
    color: #1676ff;
    padding-left: 6px;
  }
  .rule-item {
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eeeeee;
    padding: 2px 0;
    box-sizing: border-box;
    .rule-name {
      width: 90px;
      font-size: 14px;
    }
    .rule-value {
      display: flex;
      align-items: center;
      .valid-day {
        display: flex;
        align-content: center;
        .valid-day-value {
          font-size: 14px;
          width: 114px;
          height: 30px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid #8a9ab4;
          border-radius: 2px;
          box-sizing: border-box;
          .operation-icon {
            font-size: 24px;
            color: #8a9ab4;
            width: 30px;
            height: 100%;
            display: inline-block;
            text-align: center;
            line-height: 23px;
          }

          .operation-value {
            width: 53px;
            height: 27px;
            line-height: 27px;
            display: inline-block;
            text-align: center;
            color: #111a34;
            padding: 0;
          }
          .decrement {
            border-right: 0.5px solid #8a9ab4;
          }
          .increment {
            border-left: 0.5px solid #8a9ab4;
          }
        }
      }

      .switch {
        /deep/ .van-checkbox__icon {
          font-size: 18px;
        }
      }
    }
  }

  .title-input {
    width: 100%;
    border: none;
    font-size: 14px;
    color: @TEXT-COLOR-0;
    padding: 14px 0;
    text-align: right;
  }

  .add-handle {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: #1676ff;
    font-weight: 500;

    .van-icon {
      font-weight: bold;
    }

    .split {
      color: #e2e2e2;
      margin: 0 16px;
    }
  }
</style>
