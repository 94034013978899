<template>
  <div>
    <Card title="签署文件">
      <div class="file-card">
        <FilesList
          v-for="(item) in templateData.fileList"
          :key="item.fileId"
          :name="item.fileName + item.fileFormat"
          :del-icon="false"
          :size="item.fileSize"
          :logo="item.logo"
        />
      </div>
    </Card>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import Card from './Card.vue'
  import FilesList from '../../components/FilesList.vue'

  export default {
    components: {
      Card,
      FilesList,
    },
    data() {
      return {}
    },
    computed: {
      ...mapState('publishModel', {
        templateData: state => state.templateData,
      }),
    },
    mounted() {},
    methods: {
    },
  }
</script>

<style lang="less" scoped>
  .card-handle {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    .txt {
      margin-left: 4px;
      color: #1676ff;
      font-weight: 500;
    }
  }

  .append-title-wrap {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding: 16px 0;
    border-bottom: 1px solid #eeeeee;

    .upload {
      display: flex;
      align-items: center;
      position: relative;
    }
    .txt {
      margin-left: 4px;
      color: #1676ff;
      font-weight: 500;
    }
    .upload-file-handle {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0;
      z-index: 1;
    }
  }
</style>
