<template>
  <div class="process-card">
    <div class="process-title-wrap">
      <div class="tag">内部参与方</div>
      <div class="tag tag-edit van-hairline--surround">
        {{ partener.defaultName }}
      </div>
    </div>

    <div class="content-wrap">
      <!-- 企业 -->
      <template v-if="isComUser">
        <div class="name">
          {{ userInfo.comName }}
        </div>
        <div class="rule-item">
          <div class="rule-name">经办人姓名</div>
          <div class="rule-value">
            <input
              class="title-input"
              placeholder="请输入真实姓名"
              type="text"
              v-model="partener.assignName"
              readonly
            />
            <van-icon
              v-if="handleActive !== 3"
              class="addressBook"
              name="manager"
              @click="selectPerson"
            />
          </div>
        </div>
        <div class="handles-rule-item">
          <div class="rule-name">选择操作</div>
          <div class="handles-btn-wrap">
            <div
              class="button-item"
              :class="{ active: handleActive === 1 }"
            >
              签署
            </div>
            <div
              class="button-item"
              :class="{ active: handleActive === 3 }"
            >
              法人签署
            </div>
            <div
              class="button-item"
              :class="{ active: handleActive === 0 }"
            >
              审批
            </div>

            <div
              class="button-item"
              :class="{ active: handleActive === 4 }"
            >
              {{ sinaName }}
            </div>
          </div>
        </div>
      </template>

      <!-- 个人 -->
      <template v-else>
        <div class="rule-item">
          <div class="rule-name">经办人姓名</div>
          <div class="rule-value">
            <input
              class="title-input"
              placeholder="请输入真实姓名"
              type="text"
              v-model="userInfo.name"
              readonly
            />
          </div>
        </div>

        <div class="rule-item">
          <div class="rule-name">手机/邮箱</div>
          <div class="rule-value">
            <input
              class="title-input"
              placeholder="请输入手机/邮箱"
              type="text"
              v-model="userInfo.telephone"
              readonly
            />
          </div>
        </div>

        <div class="handles-rule-item">
          <div class="rule-name">选择操作</div>
          <div class="handles-btn-wrap">
            <div
              class="button-item"
              :class="{ active: handleActive === 1 }"
            >
              签署
            </div>
            <div
              class="button-item"
              :class="{ active: handleActive === 0 }"
            >
              审批
            </div>
          </div>
        </div>
      </template>

      <!-- 签章选择 -->
      <van-popup
        v-model="sealShow"
        position="bottom"
        get-container="body"
        safe-area-inset-bottom
      >
        <van-picker
          title="签章选择"
          show-toolbar
          :columns="sealOptionsList"
          @confirm="sealConfirm"
          @cancel="sealShow = false"
        />
      </van-popup>

      <!-- 通讯录选择 -->
      <van-popup
        v-model="personnelshow"
        position="bottom"
        get-container="body"
        safe-area-inset-bottom
      >
        <van-picker
          title="签章选择"
          show-toolbar
          :columns="sealOptionsList"
          @confirm="sealConfirm"
          @cancel="sealShow = false"
        />
      </van-popup>

      <PersonnelSelection
        :show.sync="personnelshow"
        :list="personnelList"
        @confirm="personConfirm"
      />
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import sealApi from '@/api/seal'
  import businessRoleApis from '@/api/businessRole'
  import PersonnelSelection from './PersonnelSelection.vue'

  /* 内部参与方 */
  export default {
    name: 'Inside',
    components: { PersonnelSelection },
    props: {
      partener: {
        type: Object,
        default: () => {},
        required: true,
      },
    },

    data() {
      return {
        /** 当前选中的操作方式 */
        handleActive: '',
        /** 签章picke展示隐藏 */
        sealShow: false,
        /** 签章picke列表 */
        sealOptionsList: [],
        /** 通讯录选择picke展示隐藏 */
        personnelshow: false,
        /** 通讯录数据 */
        personnelList: [],
      }
    },
    computed: {
      ...mapState({
        /** 是否为企业用户 */
        isComUser: state => state.user.isComUser,
        /** 用户信息 */
        userInfo: state => state.user.userInfo,
        /** 选择的签章名称 */
        sinaName() {
          if (!this.partener.assignId) return '企业签章'
          const temp = this.sealOptionsList.find(
            item => this.partener.assignId === item.value
          )
          return temp ? temp.text : '企业签章'
        },
      }),
    },

    created() {
      if (this.partener.partnerSubList && this.partener.partnerSubList.length) {
        // eslint-disable-next-line prefer-destructuring
        this.handleActive = this.partener.partnerSubList[0].operateList[0]
      }
      this.getSealList()
    },

    mounted() {},
    methods: {
      async getSealList() {
        if (this.sealOptionsList.length) return
        try {
          const res = await sealApi.getSealListByStatus()
          const name = res.map(item => ({
            text: item.sealName,
            value: item.sealId,
            ...item,
          }))

          this.sealOptionsList = name
        } catch (error) {
          console.error(error)
        }
      },

      /** 确定选择签章 */
      sealConfirm(val) {
        this.$emit('change', {
          assignId: val.value,
        })
        this.sealShow = false
      },
      /** 打开通讯录 */
      async selectPerson() {
        if (this.isComUser) {
          this.personnelList =
            this.handleActive === 4
              ? await this.getSealOwners()
              : await this.getInsideContacts()
        }
        this.personnelshow = true
      },

      async getSealOwners() {
        const find = this.sealOptionsList.find(
          item => item.value === this.partener.assignId
        )
        return find
          ? find.sealOwnerList.map(item => ({
              text: item.sealOwnerName,
              value: item.sealOwnerId,
            }))
          : []
      },

      async getInsideContacts() {
        const res = await businessRoleApis.getInsideContacts({
          fristLetter: '', // 检索条件 姓名首字母
          pageNum: 1,
          pageSize: 9999,
          param: '', // 检索条件 姓名/手机号
          type: 1, // 1 在职 0 离职
          departId: '',
        })
        return (res.rowsList || []).map(item => ({
          text: item.name || item.department,
          value: item.uid,
        }))
      },

      /** 通讯录确定选择人员 */
      personConfirm(val) {
        const temp = {
          assignName: val.text,
          assignIdList: [val.value],
        }
        this.$emit('change', temp)
      },
    },
  }
</script>

<style lang="less" scoped>
  .process-card {
    background-color: #fff;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    box-shadow: 0px 2px 6px 0px #f6f8fb;
    margin-top: 14px;

    .process-title-wrap {
      background: #eeeeee;
      display: flex;
      align-items: center;
      font-size: 12px;
      padding: 12px;
      position: relative;

      .tag {
        color: #fff;
        font-size: 12px;
        background-color: #414c5c;
        border-radius: 2px;
        padding: 2px 5px;
        margin-right: 12px;
      }

      .tag-edit {
        background: transparent;
        color: #333333;
        border: 1px solid #c5c5c5;
      }

      .delete-icon {
        position: absolute;
        right: -1px;
        top: 0;
        width: 16px;
        height: 14px;
      }
    }

    .content-wrap {
      padding: 12px;
    }
  }
  .name {
    font-size: 16px;
    color: #111a34;
    font-weight: 500;
    padding: 7px 0;
  }

  .addressBook {
    font-size: 16px;
    color: #1676ff;
    padding-left: 6px;
  }
  .rule-item {
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eeeeee;
    padding: 2px 0;
    box-sizing: border-box;
    .rule-name {
      width: 90px;
      font-size: 14px;
    }
    .rule-value {
      display: flex;
      align-items: center;
      .valid-day {
        display: flex;
        align-content: center;
        .valid-day-value {
          font-size: 14px;
          width: 114px;
          height: 30px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid #8a9ab4;
          border-radius: 2px;
          box-sizing: border-box;
          .operation-icon {
            font-size: 24px;
            color: #8a9ab4;
            width: 30px;
            height: 100%;
            display: inline-block;
            text-align: center;
            line-height: 23px;
          }

          .operation-value {
            width: 53px;
            height: 27px;
            line-height: 27px;
            display: inline-block;
            text-align: center;
            color: #111a34;
            padding: 0;
          }
          .decrement {
            border-right: 0.5px solid #8a9ab4;
          }
          .increment {
            border-left: 0.5px solid #8a9ab4;
          }
        }
      }

      .switch {
        /deep/ .van-checkbox__icon {
          font-size: 18px;
        }
      }
    }
  }

  .title-input {
    width: 100%;
    border: none;
    font-size: 14px;
    color: @TEXT-COLOR-0;
    padding: 14px 0;
    text-align: right;

    &:disabled {
      background: transparent;
      color: #8c8c8c;
    }
  }

  .handles-rule-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
    margin-top: 12px;
  }

  .handles-btn-wrap {
    display: flex;
    flex-wrap: wrap;
    padding: 14px 0 7px;
    .button-item {
      padding: 6px 10px;
      color: #333333;
      font-size: 12px;
      border: 1px solid #d5d5d5;
      border-radius: 4px;
      margin-right: 8px;
      position: relative;
      opacity: .7;
      pointer-events: none;
    }
    .seal {
      position: relative;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .setting-icon {
      position: absolute;
      border: 1px solid #d5d5d5;
      padding: 6px 10px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-left: none;
      top: -1px;
      bottom: -1px;
      right: -34px;
      line-height: 1.4em;
      border-color: #1676ff;
    }
    .active {
      border-color: #1676ff;
      color: #1676ff;
      opacity: 1;
    }
  }
</style>
