<template>
  <div class="publish-template">
    <!-- 签署规则 -->
    <RulesCard />

    <!-- 签署文件 -->
    <FileCard />

    <!-- 参与方 -->
    <ProcessCard />

    <!-- 抄送 -->
    <MakeCopy />
    <div class="zhan"></div>

    <div class="bottom-button-wrap">
      <van-button type="info" block @click="handleLaunch">直接发起</van-button>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import publishApis from '@/api/publish'
import contractBusinessApi from '@/api/contractBusiness'
import { getRandom } from '@/utils/index'
import RulesCard from './components/RulesCard.vue'
import FileCard from './components/FileCard.vue'
import ProcessCard from './components/ProcessCard.vue'
import MakeCopy from './components/MakeCopy.vue'

export default {
  components: {
    FileCard,
    RulesCard,
    ProcessCard,
    MakeCopy,
  },
  data() {
    return {}
  },
  created() {
    this.pageInit()
  },
  computed: {
    ...mapState('publishModel', {
      partnerInfo: (state) => state.partnerInfo,
      templateData: (state) => state.templateData,
    }),
    ...mapGetters('publishModel', ['isVariable']),
    businessId() {
      return this.$route.query.businessId
    },
  },
  methods: {
    ...mapMutations('publishModel', {
      setTemplateData: 'setTemplateData',
      updatePartnerInfo: 'updatePartnerInfo',
    }),
    async pageInit() {
      if (!this.businessId) return
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
      })
      try {
        const res = await publishApis.getTemplateBusiness({
          businessId: this.businessId,
        })
        res.fileIndex = getRandom(13)
        const { partnerList, ...args } = res

        /** 通知方式适配 */
        const noti = partnerList.find((item) => item.partnerRole !== 0)
        /** 兼容后端未返回signerList字段的处理 */
        partnerList.forEach((item) => {
          item.guid = getRandom(11)
          item.batchSign = 0
          if (item.partnerRole === 0) {
            item.signerList = item.signerList || []
          } else {
            item.signerList = item.signerList || [
              {
                comId: null,
                comName: null,
                handlerName: null,
                handlerTel: null,
                userId: null,
              },
            ]
          }
        })

        this.setTemplateData({
          ...args,
          noticeWay: noti.noticeWay,
        })
        this.updatePartnerInfo(partnerList)
      } catch (error) {
        console.error(error)
      } finally {
        this.$toast.clear()
      }
    },
    // 发起
    async handleLaunch() {
      this.$toast.loading({
        message: '提交中...',
        forbidClick: true,
        duration: 0,
      })
      const data = {
        ...this.templateData,
        partnerList: this.partnerInfo,
        noticeWay: 1,
        launchSign: this.isVariable ? 0 : 1, // 如果需要填写变量则存为草稿: 0-草稿 1-发起
        numStep: this.isVariable ? 2 : 3,
        step: 1,
        titleName: this.templateData.launchTitlePrefix,
      }

      const res = await contractBusinessApi.saveWithStatus(data)
      if (res.status !== 200) {
        this.$toast.clear()
        this.$toast(res.msg)
        return
      }
      this.$toast.clear()

      /** 判断模版列表是否需要填写变量,如果需要填写则跳转变量填写页面,否则直接发起 */
      if (this.isVariable) {
        this.$router.push({
          name: 'FillVariable',
          query: {
            masterId: res.data.masterId,
          },
        })
      } else {
        this.$router.push({
          name: 'SignDetail',
          query: {
            businessId: res.data.businessId,
          },
        })
      }
    },
  },
}
</script>

<style lang="less" scoped>
.publish-template {
  background: #f4f5f6;
  overflow-x: hidden;
}

.zhan {
  height: calc(54px + constant(safe-area-inset-bottom));
  height: calc(54px + env(safe-area-inset-bottom));
}

.bottom-button-wrap {
  background-color: #fff;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 6px 12px;
  padding-bottom: calc(6px + constant(safe-area-inset-bottom));
  padding-bottom: calc(6px + env(safe-area-inset-bottom));
  z-index: 1;
}
</style>
